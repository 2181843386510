.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  color: #333;
}

.MuiContainer-root {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.MuiButton-containedPrimary {
  background-color: #1976d2;
}

.MuiButton-containedPrimary:hover {
  background-color: #1565c0;
}

.MuiCard-root {
  transition: box-shadow 0.3s ease-in-out;
}

.MuiCard-root:hover {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.MuiTypography-h4 {
  margin-bottom: 1rem;
}

.MuiBox-root {
  margin-bottom: 2rem;
}

.MuiCardContent-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.MuiTypography-h6 {
  margin-top: 0.5rem;
}

.MuiButton-outlinedPrimary {
  margin-top: 0.5rem;
}

.MuiButton-outlinedPrimary:hover {
  border-color: #1565c0;
  color: #1565c0;
}

.MuiTypography-body1 {
  color: #555;
}
