/* src/components/DiarioMayor.css */

/* Contenedor principal del diario */
.diario-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 100vh; /* Hace que el contenedor ocupe al menos toda la altura de la ventana */
    box-sizing: border-box;
}

/* Estilos para las cards del diario */
.diario-card {
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.diario-form {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.diario-form > * {
    flex: 1 1 100%;
}

.diario-button {
    flex: 1 1 100%;
    margin-top: 10px;
    padding: 10px;
    background-color: #4CAF50; /* Verde */
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.diario-button:hover {
    background-color: #45a049; /* Verde oscuro */
}

.diario-delete-button {
    padding: 5px 10px;
    background-color: #f44336; /* Rojo */
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.diario-delete-button:hover {
    background-color: #da190b; /* Rojo oscuro */
}

/* Estilos para la tabla del diario */
.table-container {
    width: 100%;
    overflow-x: auto;
}

.diario-table {
    width: 100%;
    border-collapse: collapse;
}

.diario-table th,
.diario-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.diario-table th {
    background-color: #f2f2f2;
}

/* Estilos responsive */
@media (max-width: 768px) {
    .diario-form {
        flex-direction: column;
    }

    .diario-table th,
    .diario-table td {
        white-space: nowrap;
    }

    .diario-table th,
    .diario-table td {
        padding: 6px;
    }

    .diario-table th {
        font-size: 14px;
    }

    .diario-table td {
        font-size: 13px;
    }
}

@media (max-width: 480px) {
    .diario-table th,
    .diario-table td {
        padding: 4px;
    }

    .diario-table th {
        font-size: 12px;
    }

    .diario-table td {
        font-size: 11px;
    }
}
