/* src/components/CapitalLibro.css */
.capital-libro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.libro-image-container {
    margin-top: 20px;
    width: 100%;
    text-align: center;
}

.libro-image {
    max-width: 100%;
    height: auto;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.download-button-container {
    margin-top: 10px;
}

.download-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.download-button:hover {
    background-color: #45a049;
}
